import Vue from "vue";
import VueRouter from "vue-router";
// import store from "../app/store/app.index";

Vue.use(VueRouter);

// const routes = [
//   {
//     path: '/',
//     name: 'Home',
//     component: Home
//   },
//   {
//     path: '/about',
//     name: 'About',
//     // route level code-splitting
//     // this generates a separate chunk (about.[hash].js) for this route
//     // which is lazy-loaded when the route is visited.
//     component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
//   }
// ]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  // routes
});

// g1 = Bearer eyJraWQiQ~
const localStorageKey = process.env.VUE_APP_AUTHORIZATION_STORAGE;

router.beforeEach((to, from, next) => {
  // // 새로 추가한 소스
  // console.log("to : ", to);
  // console.log("from : ", from);
  // console.log(store);
  // console.log(to.matched.slice());

  // // 로그인이 필요한 라우팅 주소라면(authRequired: true 라면)
  // if (to.meta.authRequired) {
  //   alert("로그인이 필요합니다.");
  // } else {
  //   next(); // authRequired가 필요없는 라우터는 바로 페이지 전환할 수 있음
  // }

  // unauthorized: "/account/login"
  const nearestWithUnauthorized = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.unauthorized);
  if (localStorageKey && nearestWithUnauthorized) {
    // 토큰 없는 사람 /account/login으로 고고
    if (localStorage.getItem(localStorageKey) == null) {
      return next(nearestWithUnauthorized.meta.unauthorized);
    }
  }

  // authorized: "/contents",
  const nearestWithAuthorized = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.authorized);
  if (localStorageKey && nearestWithAuthorized) {
    // 토큰 있는 사람 /contents로 바로 고고
    if (localStorage.getItem(localStorageKey) != null) {
      return next(nearestWithAuthorized.meta.authorized);
    }
  }

  // This goes through the matched routes from last to first, finding the closest route with a title.
  // eg. if we have /some/deep/nested/route and /some, /deep, and /nested have titles, nested's will be chosen.
  const nearestWithTitle = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched
    .slice()
    .reverse()
    .find((r) => r.meta && r.meta.metaTags);
  //const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document (page) title to that value.
  // EV-Infra-for-OCPP:::OCPP Mockup Client
  if (nearestWithTitle)
    document.title = document.title + ":::" + nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll("[data-vue-router-controlled]")).map(
    (el) => el.parentNode.removeChild(el)
  );

  // Skip rendering meta tags if there are none.
  if (!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags
    .map((tagDef) => {
      const tag = document.createElement("meta");

      Object.keys(tagDef).forEach((key) => {
        tag.setAttribute(key, tagDef[key]);
      });

      // We use this to track which meta tags we create, so we don't interfere with other ones.
      tag.setAttribute("data-vue-router-controlled", "");

      return tag;
    })
    // Add the meta tags to the document head.
    .forEach((tag) => document.head.appendChild(tag));

  next();
});

// import YourRoutes from '...'
// router.addRoutes(YourRoutes);

//Auto....
const routes = require.context("../", true, /[A-Za-z0-9-_,\s]+\.routes.js$/i);
routes.keys().forEach((key) => {
  console.log("routes : " + key);
  const matched = key.match(/([A-Za-z0-9-_]+)\./i);
  if (matched && matched.length > 1) {
    // const k = matched[1]
    let v = routes(key);
    router.addRoutes(v.default);
  }
});

export default router;
