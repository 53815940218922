//npm install --save vue-toastification

import Vue from "vue";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

const options = {
  // transition: "Vue-Toastification__bounce",
  // maxToasts: 20,
  // newestOnTop: true
  position: "top-right",
  timeout: 100000,
  hideProgressBar: true,

  // position: "top-right",
  // timeout: 5000,
  // closeOnClick: true,
  // pauseOnFocusLoss: true,
  // pauseOnHover: true,
  // draggable: true,
  // draggablePercent: 0.94,
  // showCloseButtonOnHover: false,
  // closeButton: "button",
  // icon: true,
  // rtl: false

  
};

Vue.use(Toast, options);