"use strict";

import Vue from 'vue';

import jsf from 'json-schema-faker';

function v1_6() {
    const ctx = require.context('./ocpp/v1.6', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const schema = {}
    ctx.keys().forEach(key => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const name = matched[1]
        // console.log("ocpp1.6" ,name, key);
        schema[name] = ctx(key)
      }
    });
    return schema;
}
function v2_0_1() {
    const ctx = require.context('./ocpp/v2.0.1', true, /[A-Za-z0-9-_,\s]+\.json$/i)
    const schema = {}
    ctx.keys().forEach(key => {
      // console.log("ctx : "+key);
      const matched = key.match(/([A-Za-z0-9-_]+)\./i)
      if (matched && matched.length > 1) {
        const name = matched[1]
        // console.log("ocpp2.0.1" ,name, key);
        schema[name] = ctx(key)
      }
    });
    return schema;
}
  

const _ocpp = {
  "ocpp1.6" : v1_6(), 
  "ocpp2.0.1" : v2_0_1(),
  generate : (version, action, id)=>{
    console.log('generate', version, action);
    const schema = _ocpp[version][action];
    console.log('generate', schema);

    var uuid = id;
    if(uuid == undefined || uuid == null || uuid == "") {
      uuid = 'id#'+_ocpp.uuidSeq ;
      _ocpp.uuidSeq = _ocpp.uuidSeq+1;
    }

    if(action.endsWith('Response')){
      return [3 , ""+uuid, jsf.generate(schema)];

    }else{
      var callAction = action;
      if(action.endsWith('Request')){
        callAction = action.substring(0, action.length - 7);
      }
      return [2 , ""+uuid,  callAction,  jsf.generate(schema)];
    }
  },

  uuidSeq : 0,
  uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
      var r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }



};

Plugin.install = function(Vue/*, options*/) {
  Vue.ocpp = _ocpp;
  window.ocpp = _ocpp;
  Object.defineProperties(Vue.prototype, {
    ocpp: {
      get() {
        return _ocpp;
      }
    },
    $ocpp: {
      get() {
        return _ocpp;
      }
    },
  });
};

Vue.use(Plugin)

export default Plugin;