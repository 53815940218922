import Vue from 'vue'
import VueConfirmDialog from 'vue-confirm-dialog'
 
Vue.use(VueConfirmDialog)
Vue.component('vue-confirm-dialog', VueConfirmDialog.default)


/**
 * https://www.npmjs.com/package/vue-confirm-dialog
 * 
 * npm install --save vue-confirm-dialog
 * 
 * <vue-confirm-dialog></vue-confirm-dialog>
 */