export default [
  {
    path: "/",
    component: () => import("@/app/root/Root.vue"),
    meta: {
      // authRequired: false,
      // unauthorized : '/login',
      // authorized : ''
      // title: 'aaaa'
    },
  },
  // // 새로운 로그인 방식 - 아이디 찾기
  // {
  //   path: "/id-help",
  //   component: () => import("@/app/account/FindID.vue"),
  //   meta: {
  //     title: "Find ID",
  //   },
  // },
  // // 새로운 로그인 방식 - 비밀번호 찾기
  // {
  //   path: "/password-help",
  //   component: () => import("@/app/account/FindPassword.vue"),
  //   meta: {
  //     title: "Find Password",
  //   },
  // },
  {
    path: "/mockup-server",
    component: () => import("@/app/mockup/OCPPServer.vue"),
    meta: {
      unauthorized: "/account/login",
      title: "OCPP Mockup Server",
    },
  },
  {
    path: "/mockup-client",
    component: () => import("@/app/mockup/OCPPClient.vue"),
    meta: {
      unauthorized: "/account/login",
      title: "OCPP Mockup Client",
    },
  },
  // 새로운 로그인 방식
  // {
  //   path: "/account/sblogin",
  //   component: () => import("@/app/account/SBLogin.vue"),
  //   meta: {},
  // },
  {
    path: "/account/login",
    component: () => import("@/app/account/Login.vue"),
    meta: {
      authorized: "/contents", // 인증된 사람은 /contents로 고고
    },
  },
  {
    path: "/account/logon",
    component: () => import("@/app/account/Logon.vue"),
    meta: {
      authorized: "/contents", // 인증된 사람은 /contents로 고고
    },
  },
  {
    path: "/account/authority",
    name: "Authority",
    component: () => import("@/app/account/NotAuthority.vue"),
    meta: {
      unauthorized: "/account/login",
    },
  },
  {
    path: "/account/logout",
    component: () => import("@/app/account/Logout.vue"),
    meta: {
      unauthorized: "/account/login",
    },
  },
  {
    path: "/account/logoff",
    component: () => import("@/app/account/Logoff.vue"),
    meta: {},
  },
  {
    path: "/contents",
    component: () => import("@/app/contents/Content.vue"),
    redirect: "/contents/dashboard",
    children: [
      // // 내정보수정 전 패스워드 확인 페이지
      // {
      //   path: "/account/myaccountpassword",
      //   component: () => import("@/app/contents/account/MyaccountPassword.vue"),
      //   meta: {
      //     // authRequired: true,
      //     unauthorized: "/account/login",
      //   },
      // },
      // // 내정보수정 페이지
      // {
      //   path: "/account/myaccount",
      //   component: () => import("@/app/contents/account/Myaccount.vue"),
      //   meta: {
      //     // authRequired: true,
      //     unauthorized: "/account/login",
      //   },
      // },
      {
        path: "dashboard",
        component: () => import("@/app/contents/dashboard/Dashboard.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "managements",
        component: () => import("@/app/contents/managements/Managements.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "equipments",
        component: () => import("@/app/contents/equipments/Equipments.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "events",
        component: () => import("@/app/contents/events/Events.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "statistics",
        component: () => import("@/app/contents/statistics/Statistics.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "settings",
        component: () => import("@/app/contents/settings/Settings.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "inquiries",
        component: () => import("@/app/contents/inquiry/Inquiries.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "usernames",
        component: () => import("@/app/contents/usernames/Usernames.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "prices",
        component: () => import("@/app/contents/prices/Prices.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      {
        path: "qna",
        component: () => import("@/app/contents/qna/Qna.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
      // // left menu - 회원관리 페이지
      // {
      //   path: "member",
      //   component: () => import("@/app/contents/softberry/SBMember.vue"),
      //   meta: {
      //     unauthorized: "/account/login",
      //   },
      // },
      // // left menu - 권한관리 페이지
      // {
      //   path: "authority",
      //   component: () => import("@/app/contents/softberry/SBAuthority.vue"),
      //   meta: {
      //     unauthorized: "/account/login",
      //   },
      // },
      {
        path: "*",
        component: () => import("@/app/contents/PageNotFound.vue"),
        meta: {
          unauthorized: "/account/login",
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import("@/app/contents/PageNotFound.vue"),
    meta: {
      unauthorized: "/account/login",
    },
  },
];
