<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    // this.$vuetify.theme.dark = true;
  },
};
</script>

<style lang="scss">
// 1:1문의 페이지네이션 active 텍스트 color
.theme--dark.v-pagination .v-pagination__item--active {
  color: black !important;
}

// Pagination.vue
// .v-pagination__navigation {
//   box-shadow: none !important;
//   .v-icon.v-icon {
//     font-size: 10px !important;
//   }
// }

.v-pagination__item {
  font-size: 14px !important;
  box-shadow: none !important;
  min-width: 24px !important;
  height: 24px !important;
}
</style>
